body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

blockquote {
  padding: 0 0 0 .6em;
  border-left: 4px solid rgba(100,100,100,.2);
  opacity: .85;
}

blockquote.primary {
  border-left: 4px solid #1890ff;
}

header.ant-layout-header {
  background: none;
}
